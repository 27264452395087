import { get } from './config'

export function getVideos() {
  return new Promise((resolve, reject) => {
    let results
    try{
      results = get('/portfolios')
    } catch (error) {
      return reject(error)
    }
    return resolve(results)
  })
}

export function getBanners() {
  return new Promise((resolve, reject) => {
    let results
    try{
      results = get('/banners')
    } catch (error) {
      return reject(error)
    }
    return resolve(results)
  })
}

export function getModels(data) {
  return new Promise((resolve, reject) => {
    let results
    try{
      results = get('/models', data)
    } catch (error) {
      return reject(error)
    }
    return resolve(results)
  })
}

export function getModels2(data) {
  return new Promise((resolve, reject) => {
    let results
    try{
      results = get('/model-2-s', data)
    } catch (error) {
      return reject(error)
    }
    return resolve(results)
  })
}